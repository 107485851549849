<template>
    <div class="notice">
        <div class="title">续期须知：</div>
        <div class="vipUser_notice" v-if="status==1">
            <div>1.续期对象为利道工会工会会员。</div>
            <div>2.续期过程中如果存在转让/受让不受影响，但不可重新上架的转让。</div>
            <div>3.工会会员身份超时半年以上，利道工会为用户自己放弃权益。</div>
        </div>
        <div class="vipUser_notice" v-else>
            <div>1.续期对象为利道工会股东会员。</div>
            <div>2.续期过程中如果存在转让/受让不受影响，但不可重新上架的转让。</div>
            <div>3.合约续期超时半年以上，将视为主动放弃权益，我司将收回权益。</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        status:String
    }
};
</script>
<style>
.notice {
}
.notice .title {
  font-size: 30px;
  color: #fff;
  margin: 20px 0
}
.vipUser_notice div{
    font-size: 30px;
    color:#D6B06F;
    margin-bottom: 20px;
    line-height:50px
}
</style>