<template>
  <div class="infomation_box">
    <div class="from_box">
      <img class="cloneBox" @click="cloneBtn" src="../../assets/cloneBtn2.png" />
      <div class="from_title">信息修改</div>
      <input-view :fromData="dataFrom.userName"></input-view>
      <Select :listArray="dataFrom.sex.list" :config="dataFrom.sex"></Select>
      <div class="input_list_v">
        <div>手机号:</div>
        <input @input="inputChange" v-model="dataFrom.phone.value" placeholder-class="" placeholder="请输入手机号" />
      </div>
      <input-view :fromData="dataFrom.card"></input-view>
      <input-view v-if="status==1" :fromData="dataFrom.address"></input-view>
      <div class="bank_list">
        <input v-model="bankDataCode" placeholder="请输入验证码" class="bank_input" />
        <captcha @captchaPop="captchaPop" @propShow="propShow" ref='registerCaptcha' :config="captchaConfig" class="captcha_btn codeBox s_bg_11" slot="button"></captcha>
      </div>
      <div class="fromSumit" @click="fromSumit">确认修改</div>
    </div>

  </div>
</template>
<script>
import { Toast } from "vant";
import inputView from "./inputView";
import Select from "../select";
import captcha from "@/components/captcha";
import { detailsInfo_api } from "@/api/deal";
export default {
  data() {
    return {
      bankDataCode: "",
      dataFrom: {
        userName: { label: "姓名", placeholder: "请输入姓名", value: "" },
        card: { label: "身份证号", placeholder: "请输入身份证号", value: "" },
        phone: { label: "联系方式", placeholder: "请输入手机号", value: "" },
        address: { label: "地址", placeholder: "请输入地址", value: "" },
        sex: {
          list: ["女", "男"],
          value: "",
          title: "性别:",
          text: "点击选择性别",
          boxWidth: "width:230px"
        }
      },
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: "",
        lineShow: true,
        userinfo: true
      },
    };
  },
  components: {
    inputView,
    Select,
    captcha
  },
  props: {
    status: {
      type: Number
    },
    userFrom:{
      type:Object
    }
  },
  watch: {
    dataFrom(newOld){
      console.log(newOld)
    }
  },
  parentMsg() {
    console.log(213123123);
  },
  methods: {
    childFun(res){
      console.log(res)
      this.dataFrom.userName.value = res.union_info.username;
      this.dataFrom.sex.value = res.union_info.sex;
      this.captchaConfig.mobile = res.union_info.phone;
      this.dataFrom.phone.value = res.union_info.phone;
      this.dataFrom.card.value = res.union_info.identity;
      this.dataFrom.address.value = res.union_info.address;
    },
    inputChange(e) {
      console.log(e);
      console.log(this.dataFrom.phone.value,"dataFrom.phone.value")
    },
    cloneBtn() {
      this.$emit("cloneBtn");
    },
    // 修改会员信息
    fromSumit() {
      let data = {
        code: this.bankDataCode,
        mobile: this.dataFrom.phone.value,
        union_info: {
          username: this.dataFrom.userName.value,
          phone: this.dataFrom.phone.value,
          sex: this.dataFrom.sex.value,
          identity: this.dataFrom.card.value,
          address: this.dataFrom.address.value
        }
      };
      console.log(data,"data")
      for (var i in this.dataFrom.union_info) {
        if (!this.dataFrom.union_info[i].value) {
          Toast({
            message: this.dataFrom.union_info[i].placeholder,
            duration: 1000
          });
          return false;
        }
      }
      if(!this.bankDataCode){
        this.$toast("请输入验证码");
        return false
      }
      detailsInfo_api(data).then(res => {
        if (res.code==0) {
          this.$emit("success");
        }else{
          this.$toast(res.error);
        }
      });
    },
    // 提示框
    captchaPop() {
      this.toastShow = true;
      this.captchaConfig.title = "发送成功";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
    },
    propShow() {}
  }
};
</script>
<style>
.infomation_box {
  width: 750px;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  padding-top: 150px;
}
.from_box {
  width: 660px;
  height: 1000px;
  background: linear-gradient(180deg, #fed3a8 0%, #f6ebda 100%);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 0 auto;
  padding: 60px 30px 30px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}
.from_box .bank_list {
  display: flex;
  margin-bottom: 30px;
}
.from_box .bank_input {
  width: 500px;
  height: 90px;
  border: 1px solid rgba(86, 59, 21, 0.3);
  padding-left: 30px;
  box-sizing: border-box;
}
.from_box .lineclass {
  font-size: 25px;
  padding-top: 20px;
}
.from_title {
  color: #563b15;
}
.van-cell {
  background-color: transparent;
  width: 600px !important;
  height: 90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.3);
}
.van-field__label {
  font-size: 32px;
  margin-top: 20px;
  margin-right: 0;
  width: 100px;
}
.van-field__body {
  height: 100%;
  font-size: 32px;
  color: #eee;
}
textarea {
  width: 600px;
  height: 180px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.3);
  background: transparent;
  padding: 20px;
  box-sizing: border-box;
}
.fromSumit {
  width: 272px;
  height: 86px;
  background: #d6b06f;
  box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 86px;
  margin: 10px auto 0;
}
.plass {
  font-size: 30px;
}
textarea::-webkit-input-placeholder {
  font-size: 30px;
  color: #563b15;
}
.cloneBox {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
}
input::-webkit-input-placeholder {
  /* WebKit browsers*/
  color: rgba(86, 59, 21, 1);

  font-size: 32px;
}
</style>
