<template>
    <div>
        <div class="input_list_v">
            <div>{{fromData.label}}：</div>
            <input v-model="fromData.value" placeholder-class="" :placeholder="fromData.placeholder"/>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        fromData:{
            type: Object,
        }
    },
    created() {

    }
};
</script>
<style>
.input_list_v {
  width: 600px;
  height: 90px;
  line-height:90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.3);
  color:#563B15;
  font-size: 32px;
  display: flex;
  padding-left:22px;box-sizing: border-box;
  margin: 20px 0
}
.input_list_v input{
    border:none;
    background:transparent;
    height:45px;
    line-height: 45px;
    color:#563b15;
    margin-top: 20px;
    width: 350px    
}

.exper{}
</style>
