<template>
    <div class="stepList">
        <div class="step_title">续期流程：</div>
        <div class="vipUser" v-if="status==1">
            <img src="../../assets/vipUserIcon.png"/>
        </div>
        <div class="vipUser" v-else>
            <img src="../../assets/vipUserIcon1.png"/>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props:{
    status:{
      type:String
    }
  },
  methods() {

  }
};
</script>
<style>
.stepList {
}
.step_title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 37px;
}
.vipUser {
  display: flex;

}
.vipUser img{
    width:636px
}

</style>

